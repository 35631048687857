import React from 'react';

import {DefaultLayout} from '../components/layout';
import {QueryData} from '../common/types/query-data';

const About = (props: {data: QueryData}) => {

  return (
    <div>
      <DefaultLayout>
        about
      </DefaultLayout>
    </div>
  );
};

export default About;
